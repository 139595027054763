import { Component, OnInit } from "@angular/core";
import { SidebarService } from "../sidebar/sidebar.service";
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/localstorage/localstorage.service';
import { AutenticacionModel } from '../../models/autenticacion.model';
import { VariablesEnum } from '../../enum/variables.enum';
import { OFP_USUARIO } from '../../models/nomina-conade.model';
import { AsuntoService } from "../../services/oficialia-partes/asunto.service";
import { OFP_ASUNTO_SEMAFORO } from "../../models/asunto.model";
import { UsuarioBaseGeneralModel } from '../../models/usuario.model';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {

  public usuario = new UsuarioBaseGeneralModel;
 public listAsuntosTemp: OFP_ASUNTO_SEMAFORO[]=[];
  public listAsuntos: OFP_ASUNTO_SEMAFORO[] = [];

  public myMath = Math;
  constructor(public sidebarservice: SidebarService, public router: Router, private localStorageService: LocalStorageService,private asuntoService: AsuntoService) {
    this.usuario = JSON.parse(localStorageService.getJsonValue(VariablesEnum.usuario_conade));
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

    if ($("#wrapper").hasClass("nav-collapsed")) {
      // unpin sidebar when hovered
      $("#wrapper").removeClass("nav-collapsed");
      $("#sidebar-wrapper").unbind("hover");
    } else {
      $("#wrapper").addClass("nav-collapsed");
      $("#sidebar-wrapper").hover(
        function () {
          $("#wrapper").addClass("sidebar-hovered");
        },
        function () {
          $("#wrapper").removeClass("sidebar-hovered");
        }
      )

    }



  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

 async ngOnInit() {

    //const respuesta = await this.consultarAsuntos();
    /* this.listAsuntosTemp=respuesta.response


   if (this.listAsuntosTemp.length > 0)
    {
      this.listAsuntos = this.listAsuntosTemp.filter(function (el) {
        return el.semaforo != "VERDE";
      });
    }

   this.listAsuntos.forEach(x => {


     if (x.diaS_RESTANTES < 0) {

       //Convierte a positivo los numeros negativos
       x.diaS_RESTANTES = this.myMath.abs(x.diaS_RESTANTES);
       x.leyenda = 'dias retrasado';
     } else {
       x.leyenda = 'dias restantes';
     }

   })
 */
   }

  signOut() {
    this.localStorageService.clearToken();
    // this.router.navigateByUrl('conade/auth/signin');
    this.router.navigate(['/conade/autenticacion/signin']);
  }

  async consultarAsuntos() {
    let respuesta = null;


/*
    respuesta = await this.asuntoService.consultarAsuntosUsuario('?USROL_ID=' + this.usuario.usroL_ID+'&ACCION=SELECCION');


 */
    return respuesta;
  }

   visualizaAsunto(idasunto: number)
   {

    this.router.navigate([`conade/eventos/editar/${idasunto}`]);
   }


}
