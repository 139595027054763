import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from '../main/main.service';
import { AsuntoModel, OFP_ASUNTOARCHIVOS } from '../../models/asunto.model';

@Injectable({
  providedIn: 'root'
})
export class AsuntoService extends MainService {

  constructor(public http: HttpClient) {
    super(http);
  }

  consultar(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ASUNTO_/Consultar' + filtro);
  }

  consultarAsuntosUsuario(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/BUS_OFP_ASUNTO_/Consultar' + filtro);
  }

  consultarxRolUsuario(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/VIEW_OFP_ASUNTO_/Consultar' + filtro);
  }


  operacion(objeto: AsuntoModel) {
    return this.postAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ASUNTO_/Agregar', objeto);
  }
  agregarArchivo(objeto: Array<OFP_ASUNTOARCHIVOS>) {
    return this.postAsync(this.gatewayOficialiaPartesURL + 'CARGARCHIVOS/OFP_CARGARCHIVOS_/AgregarAdjunto', objeto);
  }

  consultarArchivosAsunto(filtro: string) {
    return this.getAsync(this.gatewayOficialiaPartesURL + 'Asunto/SP_OFP_ASUNTO_ARCHIVOS_/Consultar' + filtro);
  }

  modificar(objeto: AsuntoModel) {
    return this.putAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ASUNTO_/Actualizar', objeto);
  }

  eliminar(objeto: AsuntoModel) {
    return this.deleteAsync(this.gatewayOficialiaPartesURL + 'Asunto/OFP_ASUNTO_/Eliminar');
  }

  generarCodigo(objeto: any) {
    return this.postAsync(this.gatewayOficialiaPartesURL + 'CARGARCHIVOS/OFP_CARGARCHIVOS_/GenerarCodigo/' + objeto, null);
  }

  agregarAsuntoUsuario(objeto: AsuntoModel, usuario: string) {
    return this.postHeaderAsync(this.gatewayOficialiaPartesURL + 'Asunto/Asuntos/Agregar', objeto, usuario);
  }

}
