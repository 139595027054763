<!--Start topbar header-->
<header class="topbar-nav">
  <nav id="header-setting" class="navbar navbar-expand  bg-white navbar-static-top">
    <ul class="navbar-nav mr-auto align-items-center">
      <li class="nav-item">
        <a class="nav-link toggle-menu" (click)="toggleSidebar()">
          <i class="icon-menu menu-icon"></i>
        </a>
      </li>
    </ul>


    <ul class="navbar-nav align-items-center right-nav-link">
      <li class="nav-item border-left" ngbDropdown placement="bottom-right">
        <a
          href="javascript:;"
          class="nav-link dropdown-toggle dropdown-toggle-nocaret"
          id="dropdownBasic4"
          ngbDropdownToggle
        >
        <span class="user-profile"><i class="ti-settings"></i></span>
          <p class="ml-3 mb-0 float-right extra-small-font hidden-xs">
            {{ usuario.us_correo }}
          </p>
        </a>
        <ul
          ngbDropdownMenu
          aria-labelledby="dropdownBasic4"
          class="dropdown-menu dropdown-menu-right animate__animated animate__fadeInUp animate__faster"
        >
          <li class="dropdown-item user-details">
            <a href="javaScript:;">
              <div class="media">
                <div class="avatar"></div>
                <div class="media-body">
                  <h6 class="mt-2 user-title">{{ usuario.us_correo }}</h6> 
                </div>
              </div>
            </a>
          </li>
          <li class="dropdown-divider"></li>
          <li class="dropdown-item" (click)="signOut()">
            <i class="icon-power mr-2"></i> Cerrar sesión
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</header>
<!--End topbar header-->
