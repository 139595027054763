import { Injectable } from '@angular/core';
import { VariablesEnum } from '../../enum/variables.enum';
import { UsuarioModel } from '../../models/usuario.model';
import { StorageService } from '../webstorage/web-storage.service';

const SECRET_KEY = 'conade';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {




  constructor(
    private storageService: StorageService
  ) {
  }

  setJsonValue(key: string, value: any) {
    this.storageService.setItem(key, value);
  }

  getJsonValue(key: string) {
    const session = this.storageService.getItem(key);
    return session;
  }

  clearToken() {
    this.storageService.clearToken();
  }

  guardarUsuarioActual(usuario: any) {
    // return localStorage.setItem(VariablesEnum.usuario, JSON.stringify(usuario));
    return this.storageService.setItem(VariablesEnum.usuario, JSON.stringify(usuario));
  }

}
