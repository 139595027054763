export class UsuarioModel {
  user?: string;
  password?: string;
}

export class UsuarioAreaModel {
  area?: string;
  us_expediente?: string;
  nombreCompleto?: string;
  puesto?: string;
  us_regimen?: string;
  us_correo?: string;
  us_cuenta?: string;
  id_area?: string;
  tipo_area?: string;
  us_nombres?: string;
  us_paterno?: string;
  us_materno?: string;
}

export class OFP_Usuario {
  accion?: string;
  usU_ID?: number;
  usU_DIRECCION_GENERAL?: number;
  usU_SUBDIRECCION_GENERAL?: number;
  usU_SUBDIRECCION?: number;
  usU_DIRECCION?: number;
  usU_OFICINA?: number;
  usU_OFICINADETALLE?: string;
  caT_TITULO_ID?: number;
  ofP_CAT_ROL_ID?: number;
  usU_FUNCIONARIO?: string;
  usU_CORREO?: string;
  usU_CUENTA?: string;
  usU_TITULAR?: boolean;
  usU_INCLUSION?: string;
  siaR_CAT_PUESTO_ID?: number;
  subdireccion?: string;
  direccion?: string;
  oficina?: string;
  titulo?: string;
  rol?: string;
}

export class UsuarioBaseGeneralModel{
  us_id?:number;
  us_nombre:string;
  us_apaterno:string;
  us_materno:string;
  us_cuenta:string;
  r_nombre:string;
  us_correo:string;
}
