import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RespuestaModel } from '../../models/respuesta.model';


@Injectable({
  providedIn: 'root'
})
export class MainService {
  // DEV/QA
  gatewayOficialiaPartesURL = 'http://10.10.0.29:8088/';
  gatewayAutenticacionURL = 'http://10.10.0.32:9596/';

  //LIGAS DE QA

  //gatewayConfiguracionGlobalURL = 'http://10.10.0.32:8095/';
  //gatewayBaseGeneralURL = 'http://10.10.0.29:8091/';

  //gatewayBaseGeneralURL = 'https://sisaarapi.conade.gob.mx/'; //QA
  //gatewayBaseGeneralURL = 'http://10.10.0.32:9594/';
  gatewayBaseGeneralURL = 'https://sisaar.conade.gob.mx/Microservicios/MS_CALIDAD/';
  //gatewayBaseGeneralURL = 'http://172.31.1.207:8011/'   //QA
  //gatewayBaseGeneralURL = 'https://localhost:44362/';
  //gatewayBaseGeneralURL = 'http://sisaarqa/BACKEND/';
  //gatewayBaseGeneralURL = 'http://54.215.193.75:8124/';
  
 // PROD
  gatewayNotificacion = 'http://10.10.0.29:8082/';
  gatewayJasper = 'http://10.10.0.29:8080/';
  gatewaySiarURL = 'http://10.10.0.29:8084/';

  //LIGAS DE PRODUCCION
  gatewayConfiguracionGlobalURL = 'https://sisaar.conade.gob.mx/Microservicios/MS_GLOBALCONFIG/';
  //gatewayBaseGeneralURL = 'https://sisaar.conade.gob.mx/Microservicios/MS_CALIDAD/';

  constructor(public httpClient: HttpClient) { }
  getAsync(url: string, objecto: any|null = null): Promise<any> {

    return new Promise(resolve => {
      const subscription = this.httpClient.get<any>(url)
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data);

          }, error => {

            subscription.unsubscribe();

            resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: error.error.text
            } as RespuestaModel);
          });
    });
  }

  postAsync(url: string, objeto: any): Promise<any> {
    return new Promise(resolve => {

      const subscription = this.httpClient.post(url, objeto)
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { exito: true } as RespuestaModel);
          },
          error => {

            subscription.unsubscribe();

            return resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: null
            } as RespuestaModel);
          });
    });
  }


  postHeaderAsync(url: string, objeto: any, headerValue: string): Promise<any> {

    return new Promise(resolve => {

      let encabezado = new HttpHeaders();
      encabezado.append('user', headerValue);
      const subscription = this.httpClient.post(url, objeto, { headers: encabezado})
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { exito: true } as RespuestaModel);
          },
          error => {

            subscription.unsubscribe();

            return resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: null
            } as RespuestaModel);
          });
    });
  }

  putAsync(url: string, objeto: any): Promise<any> {

    return new Promise(resolve => {

      const subscription = this.httpClient.put(url, objeto)
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { exito: true } as RespuestaModel);
          },
          error => {

            subscription.unsubscribe();

            return resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: null
            } as RespuestaModel);
          });
    });
  }

  deleteAsync(url: string, objeto: any|null = null): Promise<any> {

    return new Promise(resolve => {
      if (objeto === null) {
        const subscription = this.httpClient.delete<any>(url)
          .subscribe(
            data => {

              subscription.unsubscribe();

              resolve(data ? data : { exito: true } as RespuestaModel);

            }, error => {

              subscription.unsubscribe();

              resolve({
                exito: false,
                mensaje: error.message.toString(),
                respuesta: null
              } as RespuestaModel);
            });
      } else {
        const subscription = this.httpClient.delete<any>(url, objeto)
          .subscribe(
            data => {

              subscription.unsubscribe();

              resolve(data ? data : { exito: true } as RespuestaModel);

            }, error => {

              subscription.unsubscribe();

              resolve({
                exito: false,
                mensaje: error.message.toString(),
                respuesta: null
              } as RespuestaModel);
            });
      }

    });
  }


  get() {
    return new Promise(resolve => {
      const subscription = this.httpClient.get('../../../../assets/datos/plantilla.json', { responseType: 'text' })
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data);

          }, error => {

            subscription.unsubscribe();

            resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: error.error.text
            } as RespuestaModel);
          });
    });
  }

  obtenerUsuariosCSV_() {
    return new Promise(resolve => {
      const subscription = this.httpClient.get('../../../../assets/datos/usuarios.json', { responseType: 'text' })
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data);

          }, error => {

            subscription.unsubscribe();

            resolve({
              exito: false,
              mensaje: error.message.toString(),
              respuesta: error.error.text
            } as RespuestaModel);
          });
    });
  }

}

