import { ReporteComponent } from './Page/reporte/reporte.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';


import { FullLayoutComponent } from "./@Common/layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./@Common/layouts/content/content-layout.component";
import { AuthGuard } from './@Common/guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'conade/autenticacion',
    pathMatch: 'full',
  },
  {
    path: 'conade', component: FullLayoutComponent,
    data: { title: 'full Views' },
    children: [
      {
        path: 'inicio',
        loadChildren: () => import('./@Common/pages/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'especiales',
        loadChildren: () => import('./@Conade/Eventos/eventos.module').then(m => m.EventosModule),
        canActivate: [AuthGuard]
      }
      ,
      {
        path: 'preparacion-apoyo',
        loadChildren: () => import('./@Conade/Materiales/materiales.module').then(m => m.MaterialesModule),
        canActivate: [AuthGuard]
      }
      ,
      {
        path: 'fundamentales',
        loadChildren: () => import('./@Conade/eventos-especiales/eventos-especiales.module').then(m => m.EventosEspecialesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'preparacion',
        loadChildren: () => import('./@Conade/eventos-comprobados/eventos-comprobados.module').then(m => m.EventosComprobadosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'beneficiario-directo',
        loadChildren: () => import('./@Conade/beneficiarios-apoyo-directo/beneficiarios-apoyo-directo.module').then(m => m.BeneficiariosApoyoDirectoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'apoyo-directo-beneficiarios',
        loadChildren: () => import('./@Conade/apoyo-directo-beneficiario/apoyo-directo-beneficiario.module').then(m => m.ApoyoDirectoBeneficiarioModule),
        canActivate: [AuthGuard]
      }
    ]
  },

  {
    path: 'conade',
    component: ContentLayoutComponent,
    data: { title: 'content Views' },
    children: [
      {
        path: 'autenticacion',
        loadChildren: () => import('./@Common/auth/auth.module').then(m => m.AuthModule),
        canActivate: [AuthGuard]

      },
      {
        path: 'content',
        loadChildren: () => import('./@Common/pages/content-pages/content-pages.module').then(m => m.ContentPagesModule)
        // ,
        // canActivate: [AuthGuard]

      }

    ]
  },
  { path: '**', redirectTo: 'conade/content/error-404' }
  /* {path:'reporte',component:ReporteComponent} */

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

