import { RouteInfo } from './sidebar.metadata';
import { RolesEnum } from 'src/app/@Common/enum/roles.enum';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '', title: 'Inicio', icon: 'zmdi zmdi-home', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/conade/inicio', title: 'Inicio', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: true, colaborador: true }
    ],
    coordinador: true,
    recepcion: true,
    colaborador: true
  },
  {
    path: '', title: 'Eventos', icon: 'fa fa-archive', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/conade/especiales/lista/all', title: 'Especiales', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: true },
      { path: '/conade/fundamentales/lista', title: 'Fundamentales', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: true },
      { path: '/conade/preparacion/lista', title: 'Preparacion', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: true },
      { path: '/conade/preparacion-apoyo/lista', title: 'Preparacion Apoyo Directo', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: true },

    ],
    coordinador: true, recepcion: true, colaborador: true
  },
  {
    path: '', title: 'Beneficiarios', icon: 'fa fa-user', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/conade/beneficiario-directo/lista', title: 'Beneficiarios Apoyo Directo', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: true },
    ],
    coordinador: true, recepcion: true, colaborador: true
  }
];

export const ROUTES_BENEFICIARIO: RouteInfo[] = [

  {
    path: '', title: 'Inicio', icon: 'zmdi zmdi-home', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/conade/inicio', title: 'Inicio', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: true, colaborador: true }
    ],
    coordinador: true,
    recepcion: true,
    colaborador: true
  },
  {
    path: '', title: 'Beneficiarios', icon: 'fa fa-user', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/conade/apoyo-directo-beneficiarios/lista', title: 'Apoyo Directo Beneficiario', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], coordinador: true, recepcion: false, colaborador: true },
    ],
    coordinador: true, recepcion: true, colaborador: true
  }
];
