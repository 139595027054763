import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';


import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';

import { TemplateModule } from './@Common/template/template.module';


import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { SidebarService } from './@Common/template/sidebar/sidebar.service';
import { FullLayoutComponent } from './@Common/layouts/full/full-layout.component';
import { ContentLayoutComponent } from './@Common/layouts/content/content-layout.component';
import { CommonModule } from '@angular/common';
import { NgbAccordionModule} from  '@ng-bootstrap/ng-bootstrap';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};


import * as $ from 'jquery';
import { MainService } from './@Common/services/main/main.service';
import { SharedModule } from './@Common/shared/module/shared.module';
import localeEsMX from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { UsuarioInterceptor } from './@Common/interceptors/Usuario.interceptor';
import { ReporteComponent } from './Page/reporte/reporte.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
registerLocaleData(localeEsMX, 'es-MX');




@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TemplateModule,
    SharedModule,
    ToastrModule.forRoot(),
    UiSwitchModule,
    AutocompleteLibModule,
    CommonModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyDKXKdHQdtqgPVl2HI2RnUa_1bjCxRCQo4' })
  ],
  exports:[ NgbAccordionModule],
  providers: [
    SidebarService,
    MainService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: HTTP_INTERCEPTORS, useClass: UsuarioInterceptor, multi: true },



  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
