import { HeadersEnum } from 'src/app/@Common/enum/headers.enum';
import { Injectable } from '@angular/core';
// import { SecureStorage } from 'secure-web-storage';


import * as CryptoJS from 'crypto-js';
import * as SHA1 from 'js-sha1';
import { VariablesEnum } from '../../enum/variables.enum';
import { UsuarioModel } from '../../models/usuario.model';

// const SecureStorage = require('secure-web-storage');


@Injectable({
  providedIn: 'root'
})

export class StorageService {
  secretKey = 'conade';


  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
  }


  setItem(key: string, value: any) {
    const valorEncryptado = this.encrypt(JSON.stringify(value));
    localStorage.setItem(key, valorEncryptado);
  }

  getItem(key: string) {
    let value = localStorage.getItem(key);
    if (value) {
      value = this.decrypt(value);
    }
    return value;
  }

  clearToken() {
    localStorage.removeItem(VariablesEnum.usuario_conade);
    localStorage.removeItem(VariablesEnum.usuario);
    localStorage.removeItem(HeadersEnum.ACCION);
    localStorage.removeItem(HeadersEnum.USUARIO);

  }


  encryptPassword(psw: string) {
    const hash = SHA1(psw);
    return hash;
  }


}
