import { Component, OnInit } from '@angular/core';
import { ROUTES, ROUTES_BENEFICIARIO } from './sidebar-routes.config';
import { OFP_USUARIO } from '../../models/nomina-conade.model';
import { LocalStorageService } from '../../services/localstorage/localstorage.service';
import { VariablesEnum } from '../../enum/variables.enum';
import { RouteInfo } from './sidebar.metadata';
import { RolesEnum } from '../../enum/roles.enum';
import { SidebarService } from './sidebar.service';
import { UsuarioBaseGeneralModel } from '../../models/usuario.model';
//import { ROUTES } from '../../../../../BACKUP/src/app/@Common/template/sidebar/sidebar-routes.config';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: RouteInfo[];
  public usuarioActual: OFP_USUARIO;
  public sideBar: RouteInfo[] = [];



  constructor(
    private localStorageService: LocalStorageService,
    public sidebarservice: SidebarService,
  ) {
    }

  async ngOnInit() {

    this.usuarioActual = JSON.parse(this.localStorageService.getJsonValue(VariablesEnum.usuario_conade));
    let user_logged:UsuarioBaseGeneralModel = JSON.parse(this.localStorageService.getJsonValue(VariablesEnum.usuario_conade));


    $.getScript('./assets/js/app-sidebar.js');
    if(user_logged.r_nombre.trim() == 'beneficiario.directo'){
      this.menuItems = ROUTES_BENEFICIARIO;
    }else{
      this.menuItems = ROUTES;
    }


  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

    if ($("#wrapper").hasClass("nav-collapsed")) {
      // unpin sidebar when hovered
      $("#wrapper").removeClass("nav-collapsed");
      $("#sidebar-wrapper").unbind("hover");
    } else {
      $("#wrapper").addClass("nav-collapsed");
      $("#sidebar-wrapper").hover(
        function () {
          $("#wrapper").addClass("sidebar-hovered");
        },
        function () {
          $("#wrapper").removeClass("sidebar-hovered");
        }
      )

    }
  }


 public valido(menu: RouteInfo){
    switch(this.usuarioActual.ofP_CATROLES_ID){
      case RolesEnum.RECEPCION:
          if(menu.recepcion){
            return true;
          } else {
            return false;
          }
        break;
        case RolesEnum.COORDINADOR_EJECUTIVO:
          if(menu.coordinador){
            return true;
          } else {
            return false;
          }
        break;
        case RolesEnum.COLABORADOR:
          if(menu.colaborador){
            return true;
          } else {
            return false;
          }
        break;
    }


  }


}
