import { HeadersEnum } from 'src/app/@Common/enum/headers.enum';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/localstorage/localstorage.service';

@Injectable()
export class UsuarioInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const usuario = localStorage.getItem(HeadersEnum.USUARIO);
    const accion = localStorage.getItem(HeadersEnum.ACCION);

    if (!usuario) {
      return next.handle(req);
    }

    //
    const headers = new HttpHeaders({
      'user': usuario === null ? '' : usuario,
      'accion': accion === null ? '' : accion
    });

    const cloneRequest = req.clone({headers});
    return next.handle(cloneRequest);
  }
}
